import { createStore } from 'vuex';
import AuthModule from './authStore';
import GeneralStore from './generalStore';
const store = createStore({
    modules: {
        auth: AuthModule,
        general: GeneralStore // this store is namespaceed
    },
    state: {
        //
    },
    mutations: {
        //
    },
    actions: {
        //
    },
    getters: {
        //
    }
})

export default store;
