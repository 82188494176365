export default {
    purchase_order_statuses: {
        draft: 1,
        review: 2,
        request_changes: 3,
        approved: 4,
        send_to_mfg: 5,
        confirmed: 6,
        closed: 7,
        cancelled: 8
    },
    purchase_order_categories: {
        product_existing:1,
        product_new:2,
        label_carton_film:3,
        bottle_cap_packaging:4,
        bulk:5,
        rebottle_relabel:6,
        rework:7,
        marketing:8,
        others:9,
    }
}