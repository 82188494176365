import location from "./location";
import supplier from "./supplier";
import part from "./part";
import bom from "./bom";
import bol from "./bol";
import shipment from "./shipment";
import production from "./production";
import common from './common';
import purchaseOrder from './purchaseOrder';
export default {
    location,
    supplier,
    common,
    part,
    bom,
    bol,
    shipment,
    production,
    purchaseOrder
};