import axios from 'axios';
import store from './store/index.js';
import route from './routes/index.js';
axios.defaults.baseURL = `${process.env.VUE_APP_BACKEND_API_URL}/api/v1/`
axios.defaults.headers.common['Authorization'] = `Bearer ${store.getters.authData.token}`;
axios.interceptors.response.use(
    response => response,
    errObj => {
        if (!errObj.response) {
            return Promise.reject(errObj);
        }
        if (errObj.response.status == 401) {
            // UNAUTHORIZED
            route.replace({name: 'logout'});
        } else if (errObj.response.status == 404) {
            // NOT_FOUND
            route.replace({name: 'not_found'})
        } else if (errObj.response.status == 403) {
            // FORBIDDEN
            // update the roles and permissions for the user
            store.dispatch('showError', {title: 'Action not allowed', body: errObj.response.message, type: 'error'})
            store.dispatch('fetchUserData', {token: store.getters.authData.token, storeToken: true})     
        }
        return Promise.reject(errObj)
    }
)