<template>
    <div class="flex fixed bottom-6 w-66 py-3 px-6 rounded left-1/2 -ml-32 z-50" v-if="errorObj.title || errorObj.body">
        <!-- Info -->
        <div class="mb-2 rounded px-4 py-3" :class="bgColor ?? 'bg-gray-100'">
            <div class="flex items-start">
                <!-- alert icon -->
                <div class="py-1" v-html="icon"></div>
                <div>
                    <h3 :class="textColor ?? 'text-white'">{{ errorObj.title }}</h3>
                    <p class="text-sm" :class="textColor ?? 'text-white'">{{ errorObj.body }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            textColor: '',
            bgColor: '',
            icon: ''
        }
    },
    mounted() {
        this.alertIcon();
    },
    watch: {
        '$store.getters.errorObj.type': {
            handler() {
                this.alertIcon()
            }
        }
    },
    methods: {
        alertIcon() {
            const alertType = this.errorObj.type;
            if (alertType == 'success') {
                this.textColor = 'text-white'
                this.bgColor = 'bg-neutral-800'
                this.icon = `<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="mt-1 mr-2">
                                <path d="M1.16675 6.83301L4.50008 10.1663L12.8334 1.83301" stroke="#10B981" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>`
            } else if(alertType == 'info') {
                this.textColor = 'text-gray-800'
                this.bgColor = 'bg-gray-100'
                this.icon = `<svg class="fill-current h-6 w-6 mr-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                        </svg>`;
            } else if(alertType == 'warning') {
                this.textColor = 'text-yellow-700'
                this.bgColor = 'border-yellow-500 text-yellow-900 bg-yellow-100'
                this.icon = `<svg class="fill-current h-6 w-6 mr-4 text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 5h2v6H9V5zm0 8h2v2H9v-2z" />
                        </svg>`
            } else if(alertType == 'error') {
                this.textColor = 'text-red-700'
                this.bgColor = 'border-red-500 text-red-900 bg-red-100'
                this.icon = `<svg class="fill-current h-6 w-6 mr-4 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M0 10a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm16.32-4.9L5.09 16.31A8 8 0 0 0 16.32 5.09zm-1.41-1.42A8 8 0 0 0 3.68 14.91L14.91 3.68z" />
                        </svg>`
            }
        }
    },
    computed: {
        errorObj() {
            return this.$store.getters.errorObj
        },
    }
}
</script>