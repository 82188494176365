export default {
  bom_type: {
    product: 1,
    kit: 2,
  },
  version_acceptance_percent: {
    lower: 95,
    upper: 105,
  },
  procurement_type: {
    supplied: 1,
    invoiced: 2,
    token: 3
  },
  bom_status: {
    inactive: 0,
    active: 1,
    draft: 2
  },
  bom_document_version_status: {
    draft: 1,
    cancelled: 2,
    review: 3,
    request_changes: 4,
    approved: 5, 
    rejected: 6,
    superseded: 7,
    archive: 8
  },
  bom_version_status: {
    draft: 1,
    ready: 2,
    finalized: 3,
    archive: 4,
    inactive: 5
  }
};
