<template>
  <nav class="flex" aria-label="Breadcrumb">
    <ol role="list" class="breadcrumbs flex items-center">
      <li v-for="(crumb, ci) in crumbs" :key="`breadcrumb-${ci}`" class="breadcrumb-item flex">
        <div class="flex items-center">
          <template v-if="'href' in crumb">
            <a :href="crumb.href" class="text-neutral-400 text-base ">{{ crumb.name }}</a>
          </template>
          <template v-else-if="'to' in crumb">
            <router-link :to="crumb.to" class="text-neutral-400 text-base">{{ crumb.name }}</router-link>
          </template>
          <template v-else>
            <span class="text-neutral-600 text-base">{{ crumb.name }}</span>
          </template>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
// Eg prop value for breadcrumbs
// crumbs:[
//   {name: 'Location', to: {name: 'dashboard'}},
//   {name: 'Location', href: 'dasdas'},
//   {name: 'THC', active:true},
// ],
export default {
  name: 'CpBreadcrumb',
  props: {
    crumbs: {
      type: Array,
      required: true,
    },
  },
}
</script>