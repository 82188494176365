export default { 
  location_type: {
    canprev_warehouse: 1,
    manufacturer: 2,
  },
  warehouse: {
    mfg_warehouse: 2,
    northwind: 1,
    morningside: 3,
  },
  contact_role: {
    accounting: 1,
    admin: 2,
    front_desk: 3,
    manager: 4,
    practitioner: 5,
    purchasing: 6,
    receiving: 7,
    warehouse: 8,
    other: 9
  },
  location_zone: {
    pick_pack_zone: process.env.VUE_APP_PICK_PACK_ZONE,
    warehouse_zone: process.env.VUE_APP_WAREHOUSE_ZONE,
    pick_pack_cold_room_zone: process.env.VUE_APP_PICK_PACK_COLD_ROOM_ZONE,
    warehouse_cold_room_zone: process.env.VUE_APP_WAREHOUSE_COLD_ROOM_ZONE,
    cart_zone: process.env.VUE_APP_CART_ZONE,
    receiving_zone: process.env.VUE_APP_RECEIVING_ZONE
  },
  zone: {
    nw_pick: 1,
    nw_warehouse: 2,
    nw_cr: 3,
    nw_crw: 4,
    nw_receiving: 5,
    nw_shipping: 6,
    nw_cart: 7,
    nw_trailer: 8,
    nw_qa_quarantine: 9,
    ms_pick: 10,
    ms_warehouse: 11,
    ms_cr: 12,
    ms_crw: 13,
    ms_receiving: 14,
    ms_shipping: 15,
    ms_cart: 16,
    ms_trailer: 17,
    ms_qa_quarantine: 18,
  },
  storage_condition:{
    cold_storage: 1,
    valuable: 2,
    fragile: 3,
    valueable: 4
  },
  shelf_sizes:{
    self_a:1,
    self_b:2,
    self_c:3,
    single_skid:4,
    multiple_skid:5
  },
};
