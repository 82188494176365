export default {
    supplier_type: {
        manufacturer: 1,
        all_supplers: 0
    },
    supplier_account_type: {
        customer: 1,
        supplier: 0
    },
  };
  