const generalStore = {
    namespaced: true,
    state: {
        sidebarOpen: false,
        bomVersionItem: {},
        bomVersionInstruction:{},
    },
    getters: {
        isSidebarOpen(state) {
            return state.sidebarOpen;
        },
        getBomVersionItems(state) {
            return state.bomVersionItem;
        },
        getBomVersionInstructions(state) {
            return state.bomVersionInstruction;
        }
    },
    mutations: {
        setSidebarStatus(state, payload) {
            state.sidebarOpen = payload;
        },
        setBomVersionItems(state, payload) {
            state.bomVersionItem = payload;
        },
        setBomVersionInstructions(state, payload) {
            state.bomVersionInstruction = payload;
        },
    },
    actions: {
        setSidebarStatus(context, payload) {
            context.commit("setSidebarStatus", payload);
        },
        setBomVersionItems(context, payload) {
            context.commit("setBomVersionItems", payload);
        },
        setBomVersionInstructions(context, payload) {
            context.commit("setBomVersionInstructions", payload);
        }
    },
};

export default generalStore;