<template>
    <div class="h-screen flex break-words bg-white overflow-hidden">
        <div id="page-loading-div" ref="page-loading-div" class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 hidden">
            <div class="top-1/2 my-0 mx-auto block relative w-16 h-16">
                <div class="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-600"></div>
            </div>
        </div>
        <LeftNavigationComponent ref="left-navigation-component" />
        <div class="flex flex-col w-0 flex-1 overflow-hidden" :class="{'sidebarOpen': isSidebarOpen}">
            <HeaderComponent @toggleMobileMenu="toggleMobileMenu" />
            <template v-if="showWarehouseConfirmation">
                <transition name="modal-fade">
                    <div class="fixed inset-0 overflow-y-auto z-50">
                        <div class="flex min-h-full items-center justify-center p-4 bg-black bg-opacity-10">
                            <div class="relative bg-white shadow-md rounded-lg md:w-2/6 overflow-hidden">
                            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                                <header class="modal-header" id="modalTitle">
                                    <h1 class="text-neutral-800 px-6 py-5 text-xl font-bold tracking-normal leading-tight  flex ">
                                        <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.211 1.95831C11.4344 -0.216572 14.5658 -0.216573 15.7891 1.95831L24.7176 17.8312C25.9175 19.9643 24.376 22.6 21.9286 22.6H4.0716C1.62416 22.6 0.0826728 19.9643 1.28256 17.8312L10.211 1.95831ZM14.6 17.8002C14.6 18.6838 13.8836 19.4002 13 19.4002C12.1163 19.4002 11.4 18.6838 11.4 17.8002C11.4 16.9165 12.1163 16.2002 13 16.2002C13.8836 16.2002 14.6 16.9165 14.6 17.8002ZM13 5.0002C12.1163 5.0002 11.4 5.71654 11.4 6.60019V11.4002C11.4 12.2838 12.1163 13.0002 13 13.0002C13.8836 13.0002 14.6 12.2838 14.6 11.4002V6.60019C14.6 5.71654 13.8836 5.0002 13 5.0002Z" fill="#F59E0B"/>
                                        </svg>
                                        <span class=" font-semibold px-4">It doesn't look like you are here </span>
                                    </h1>
                                </header>

                                <section class="modal-body" id="modalDescription">
                                    <div class="p-6 h-64 overflow-y-scroll scrollbar">
                                        <div class="space-y-4 sec-warehouse">
                                            <p class="text-neutral-800 text-sm tracking-normal">Are you set to the right warehouse? Confirm to dismiss this check on this page for the rest of your session.</p>
                                        </div>
                                        <Multiselect
                                            v-model="warehouseId"
                                            :searchable="false"
                                            placeholder="Select Warehouse"
                                            :close-on-select="true"
                                            :options="userData.warehouseOptions"
                                            :loading="isLoading"
                                            label="name"
                                            valueProp="id"
                                            class="custom-multiselect"
                                            @select="validationMsg = false"
                                        >
                                            <template #option="{ option }">
                                                <div class="flex items-center space-x-2">
                                                    <div class="p-1 px-2 inline-flex items-center rounded" :class="option.color">
                                                        <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_4039_881182)">
                                                                <path d="M4 27.9999V10.6666L16 5.33325L28 10.6666V27.9999" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <path d="M17.3333 17.3333H22.6666V27.9999H9.33331V19.9999H17.3333" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <path d="M17.3333 28.0001V16.0001C17.3333 15.6465 17.1929 15.3073 16.9428 15.0573C16.6928 14.8072 16.3536 14.6667 16 14.6667H13.3333C12.9797 14.6667 12.6406 14.8072 12.3905 15.0573C12.1405 15.3073 12 15.6465 12 16.0001V20.0001" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_4039_881182">
                                                                    <rect width="32" height="32" fill="white"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        <span class=" ml-2">{{ option.label }}</span> 
                                                    </div>
                                                </div> 
                                            </template>
                                            <template v-slot:singlelabel="{ value }">
                                                <div class="multiselect-single-label text-sm font-normal text-neutral-800 flex items-center space-x-2">
                                                    <div class="p-1 px-2 inline-flex items-center rounded" :class="value.color">
                                                        <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_4039_881182)">
                                                                <path d="M4 27.9999V10.6666L16 5.33325L28 10.6666V27.9999" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <path d="M17.3333 17.3333H22.6666V27.9999H9.33331V19.9999H17.3333" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <path d="M17.3333 28.0001V16.0001C17.3333 15.6465 17.1929 15.3073 16.9428 15.0573C16.6928 14.8072 16.3536 14.6667 16 14.6667H13.3333C12.9797 14.6667 12.6406 14.8072 12.3905 15.0573C12.1405 15.3073 12 15.6465 12 16.0001V20.0001" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_4039_881182">
                                                                    <rect width="32" height="32" fill="white"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        <span class=" ml-2">{{ value.label }}</span> 
                                                    </div>
                                                </div>
                                            </template>
                                        </Multiselect>
                                        <div v-if="validationMsg" class="text-red-600 text-sm">
                                            Select a Warehouse
                                        </div>
                                    </div>
                                    <div class="flex items-center justify-end w-full px-6 mb-5">
                                        <button class="flex justify-center focus:outline-none hover:shadow-md ease-in-out hover:bg-primary-600 bg-primary-500 rounded-md text-white px-8 py-2 text-sm" @click="changeWarehouse">Confirm</button>
                                    </div>
                                </section>
                            </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </template>
            <router-view></router-view>
        </div>
        <a href="javascript:void(0)" id="fieldUnsavedLink" style="visibility:hidden"></a>
    </div>
</template>
<script>
import LeftNavigationComponent from './LeftNavigationComponent.vue';
import HeaderComponent from './HeaderComponent.vue';
import commonMixin from '../../mixin'
import axios from 'axios';
import { computed } from 'vue'
import Multiselect from '@vueform/multiselect';
export default {
    data() {
        return { 
            currentLocation: { latitude: null, longitude: null },
            warehouseId: null,
            showWarehouseConfirmation: false,
            warehouse_confirmation_flag: false,
            errors: '',
            validationMsg: false,
        }
    },
    mixins: [commonMixin],
    components: {
        LeftNavigationComponent,
        HeaderComponent,
        Multiselect
    },
    provide() {
        return {
            // this properties are provided to SidebarDetailComponent
            openLocaionConfirmation: this.openLocaionConfirmation,
            warehouseSelected: computed(() => this.warehouse_confirmation_flag),
            showWarehouseConfirmation: computed(() => this.showWarehouseConfirmation)
        }
    },
    mounted() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.currentLocation.latitude = position.coords.latitude;
                    this.currentLocation.longitude = position.coords.longitude;
                    this.openLocationOnLogin();
                },
                (error) => {
                    console.error("Error getting location:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
        this.warehouseId = this.userData.kn_warehouse_id
    },
    methods: {
        toggleMobileMenu() {
            this.$refs['left-navigation-component'].toggleMenu();       
        },
        arePointsNear(checkPoint, centerPoint, km) {
            var ky = 40000 / 360;
            var kx = Math.cos(Math.PI * centerPoint.latitude / 180.0) * ky;
            var dx = Math.abs(centerPoint.longitude - checkPoint.longitude) * kx;
            var dy = Math.abs(centerPoint.latitude - checkPoint.latitude) * ky;
            return Math.sqrt(dx * dx + dy * dy) <= km;
        },
        openLocaionConfirmation(isWarehouseNotSame) {
            if(isWarehouseNotSame){
                this.showWarehouseConfirmation = true;
            } else {
                return
            }
        }, 
        openLocationOnLogin(){
            if(this.currentLocation.latitude != null){
                this.warehouse_confirmation_flag = this.userData.warehouse_confirmation_flag
                if (!this.warehouse_confirmation_flag) {
                    let userWarehouse = this.userData.warehouseOptions.find(warehouse => warehouse.id == this.userData.kn_warehouse_id)
                    const warehouseLocation = {
                        latitude: userWarehouse.latitude,
                        longitude: userWarehouse.longitude
                    };

                    if (!this.arePointsNear(this.currentLocation, warehouseLocation, 1)) {
                        this.showWarehouseConfirmation = true;
                    }
                }
                return
            }
        },
        changeWarehouse() {
            if(this.warehouseId == null) {
                this.validationMsg = true
                return false
            }
            axios({
                method: 'put',
                url: `users/${this.userData.id}`,
                data: {
                    kn_warehouse_id: this.warehouseId,
                    warehouse_confirmation_flag: 1
                }
            }).then(res => {
                if (res.status == 200) {
                    this.showWarehouseConfirmation = false;
                    this.validationMsg = false;
                    this.$store.dispatch('checkAuth');
                }
            }).catch(err => {
                if (err.response.status == 400) {
                    this.errors = err.response.data.detail;
                }
            });
        },
    },
    computed: {
        isSidebarOpen() {
            return this.$store.getters['general/isSidebarOpen'];
        },
    }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>