<template>
    <div class="side_navigations">    
        <div class="fixed inset-0 flex z-40 md:hidden" :class="{'invisible': closedMenu, 'visible': !closedMenu}" role="dialog" aria-modal="true">
        <div id="mobileMenuOpacity"
            :class="{'opacity-0 hidden': closedMenu, 'opacity-100 block': !closedMenu}"
            class="fixed inset-0 bg-neutral-600 bg-opacity-75 transition-opacity ease-linear duration-300"
            aria-hidden="true" @click="closedMenu = !closedMenu"></div>
            <div id="mobileMenu" :class="{'-translate-x-full': closedMenu, '-translate-x-0': !closedMenu}" class="relative flex-1 flex flex-col max-w-xs w-full pt-5 bg-white h-screen transition ease-in-out duration-300 transform">
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                    <button id="mobileMenuClose"
                        :class="{'opacity-0': closedMenu, 'opacity-100': !closedMenu}"
                        class="ml-1 flex items-center  h-10 w-10 rounded-full outline-none ring-2 ring-inset ring-white ease-in-out duration-300"
                        @click="closedMenu = !closedMenu">
                        <span class="sr-only">Close sidebar</span>
                        <svg class="h-6 w-6 text-white ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div class="flex-shrink-0 flex items-center px-4">
                    <img class="h-8 w-auto" src="@/assets/images/kinetics_logo.svg" alt="Kinetics" />
                </div>
                <div class="mt-5 flex-1 flex flex-col h-0 overflow-y-auto">
                    <nav class="flex-1 px-2 bg-white space-y-1">
                        <div v-for="(navSection, navSectionIndex) in navSections" :key="`${navSection.title}-section`" class="space-y-1">
                            <p :class="{'w-16': closedMenu}" class="px-2 text-[10px] font-semibold tracking-wider text-neutral-400 uppercase sectionTitle" >{{ navSection.title }}</p>
                            <div v-for="link in navSection.navLinks" :key="link.title+'bottom'">
                                <router-link v-if="checkPermission(link.link.name)" class="flex space-x-3 items-center justify-start p-2 text-neutral-600 bg-white group rounded-md hover:bg-primary-50 hover:text-primary-600"
                                    :to="link.link">
                                    <span v-if="'icon' in link" v-html="link.icon">
                                    </span>
                                    <span class="title text-sm font-medium">{{ link.title }}</span>
                                </router-link>
                            </div>
                            <div v-if="navSectionIndex != (navSections.length - 1)" class="border-t border-neutral-200 inline-block w-full pb-1"></div>
                        </div>
                    </nav>
                    <!-- Start: Settings Link -->
                    <div class="settings flex-shrink-0 flex border-t border-neutral-200 px-2 py-1">
                        <a href="#"
                        class="flex space-x-3 items-center justify-start p-2 text-neutral-600 bg-white group rounded-md hover:bg-primary-50 hover:text-primary-600 w-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="flex-shrink-0 h-5 w-5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                            d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <span class="title text-sm font-medium">Settings</span>
                        </a>
                    </div>
                    <!-- End: Settings Link -->
                </div>
            </div>
            <div class="flex-shrink-0 w-14" aria-hidden="true"></div>
        </div>
        <!-- Left Side Nav Bar -->
        <aside id="sideNavigation" :class="closedMenu ? 'md:w-14 sm:w-44 menuCollapsed' : 'sm:w-0 md:w-44'"
        class="h-full flex-shrink-0 hidden md:flex flex-col border-r duration-300 transform translate-x-0">
        <div class="flex flex-col flex-grow pt-5 bg-white">
            <div class="flex items-center flex-shrink-0 pl-2 mb-6  justify-between">
            <router-link :to="dashboardLink">
                <img class="h-auto w-auto cpLogo mr-5" src="@/assets/images/kinetics_logo.svg" alt="CanPrev" />
            </router-link>
            <button class="p-2 focus:outline-none bg-white rounded-md text-neutral-600 hover:bg-primary-50 hover:text-primary-600" @click="closedMenu = !closedMenu">
                <span class="sr-only">Open sidebar</span>
                <img v-if="closedMenu" class="h-6 w-6" src="@/assets/images/kinetics_icon.svg" alt="Kinetics" />
                <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6"
                    viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clip-rule="evenodd" />
                </svg>
            </button>
            </div>
            <div class="mt-5 flex-grow flex flex-col">
            <nav class="flex-1 px-2 bg-white space-y-1">
                <div v-for="(navSection, navSectionIndex) in navSections" :key="`${navSection.title}-section`" class="space-y-1">
                    <p :class="{'w-16': closedMenu}" class="px-2 text-[10px] font-semibold tracking-wider text-neutral-400 uppercase sectionTitle" >{{ navSection.title }}</p>
                    <div v-for="link in navSection.navLinks" :key="link.title+'bottom'">
                        <router-link v-if="checkPermission(link.link.name)"
                            class="flex space-x-3 items-center justify-start p-2 text-neutral-600 bg-white group rounded-md hover:bg-primary-50 hover:text-primary-600"
                            :to="link.link">
                            <span v-if="'icon' in link" v-html="link.icon">
                            </span>
                            <span class="title text-sm font-medium">{{ link.title }}</span>
                        </router-link>
                    </div>
                    <div v-if="navSectionIndex != (navSections.length - 1)" class="border-t border-neutral-200 inline-block w-full pb-1"></div>
                </div>
            </nav>
            <!-- Start: Settings Link -->
            <div class="settings flex-shrink-0 flex border-t border-neutral-200 px-2 py-1">
                <a href="#" v-if="checkPermission('settings')"
                class="flex space-x-3 items-center justify-start p-2 text-neutral-600 bg-white group rounded-md hover:bg-primary-50 hover:text-primary-600 w-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="flex-shrink-0 h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                    d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <span class="title text-sm font-medium">Settings</span>
                </a>
            </div>
            <!-- End: Settings Link -->
            </div>
        </div>
        </aside>
        <!-- Left Side Nav Bar -->
    </div>
</template>
<script>
/* eslint-disable */
import commonMixin from '@/mixin'
export default {
    mixins: [commonMixin],
    data() {
        return {
            navSections: [
                {
                    title: 'tasks',
                    navLinks: [
                        {
                            link: {name: 'bol_inbound_shipment_list'},
                            title: 'Shipments',
                            icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                        class="flex-shrink-0 h-5 w-5">
                                        <path d="M5.70711 11.2929C5.31658 10.9024 4.68342 10.9024 4.29289 11.2929C3.90237 11.6834 3.90237 12.3166 4.29289 12.7071L5.70711 11.2929ZM7 14L6.29289 14.7071C6.68342 15.0976 7.31658 15.0976 7.70711 14.7071L7 14ZM11.7071 10.7071C12.0976 10.3166 12.0976 9.68342 11.7071 9.29289C11.3166 8.90237 10.6834 8.90237 10.2929 9.29289L11.7071 10.7071ZM14 5V17H16V5H14ZM13 18H3V20H13V18ZM2 17V5H0V17H2ZM3 4H5V2H3V4ZM11 4H13V2H11V4ZM3 18C2.44772 18 2 17.5523 2 17H0C0 18.6569 1.34315 20 3 20V18ZM14 17C14 17.5523 13.5523 18 13 18V20C14.6569 20 16 18.6569 16 17H14ZM16 5C16 3.34315 14.6569 2 13 2V4C13.5523 4 14 4.44772 14 5H16ZM2 5C2 4.44772 2.44772 4 3 4V2C1.34315 2 0 3.34315 0 5H2ZM4.29289 12.7071L6.29289 14.7071L7.70711 13.2929L5.70711 11.2929L4.29289 12.7071ZM7.70711 14.7071L11.7071 10.7071L10.2929 9.29289L6.29289 13.2929L7.70711 14.7071ZM7 2H9V0H7V2ZM9 4H7V6H9V4ZM7 4C6.44772 4 6 3.55228 6 3H4C4 4.65685 5.34315 6 7 6V4ZM10 3C10 3.55228 9.55228 4 9 4V6C10.6569 6 12 4.65685 12 3H10ZM9 2C9.55228 2 10 2.44772 10 3H12C12 1.34315 10.6569 0 9 0V2ZM7 0C5.34315 0 4 1.34315 4 3H6C6 2.44772 6.44772 2 7 2V0Z" fill="currentColor"/>
                                    </svg>`
                        },
                        {
                            link: {name: 'inbound_list'},
                            title: 'Inbound',
                            icon:  `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                        class="flex-shrink-0 h-5 w-5">
                                        <path d="M1 13L1 14C1 15.6569 2.34315 17 4 17L14 17C15.6569 17 17 15.6569 17 14L17 13M13 9L9 13M9 13L5 9M9 13L9 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>`
                        },
                        {
                            link: {name: 'outbound_list'},
                            title: 'Outbound',
                            icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                        class="flex-shrink-0 h-5 w-5">
                                        <path d="M1 13L1 14C1 15.6569 2.34315 17 4 17L14 17C15.6569 17 17 15.6569 17 14L17 13M13 5L9 1M9 1L5 5M9 1L9 13" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>`
                        },
                        {
                            link: {name: 'direct_bol_list'},
                            title: 'Direct',
                            icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                        class="flex-shrink-0 h-5 w-5 mt-2.5">
                                        <path d="M15 1L19 5M19 5L15 9M19 5L1 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>`
                        },
                        {
                            link: { name: 'inter_wh_bol_list' },
                            title: 'Inter-WH',
                            icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 h-5 w-5">
                                        <g id="Icon" clip-path="url(#clip0_3249_318755)">
                                            <path id="Vector" d="M11 16H21" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path id="Vector_2" d="M11 16L15 20" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path id="Vector_3" d="M11 16L15 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path id="Vector_4" d="M13 8H3" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path id="Vector_5" d="M13 8L9 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path id="Vector_6" d="M13 8L9 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3249_318755">
                                                <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>`
                        }
                    ]
                },
                {
                    title: 'lists',
                    navLinks: [
                        {
                            link: {name: 'production_plan_list'},
                            title: 'Productions',
                            icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                        class="flex-shrink-0 h-5 w-5">
                                        <path
                                        d="M19 11H5M19 11C20.1046 11 21 11.8954 21 13V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V13C3 11.8954 3.89543 11 5 11M19 11V9C19 7.89543 18.1046 7 17 7M5 11V9C5 7.89543 5.89543 7 7 7M7 7V5C7 3.89543 7.89543 3 9 3H15C16.1046 3 17 3.89543 17 5V7M7 7H17"
                                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>`
                        },
                        {
                            link: {name: 'purchase_order_list'},
                            title: 'Quickbooks',
                            icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 h-5 w-5">
                                        <g clip-path="url(#clip0_6395_216563)">
                                            <path d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M9 7H10" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M9 13H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M13 17H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_6395_216563">
                                                <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>`
                        },
                        {
                            link: {name: 'parts_list', query: {tab: 'product'}},
                            title: 'Parts',
                            icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    class=" flex-shrink-0 h-5 w-5">
                                    <path d="M12 3L20 7.5V16.5L12 21L4 16.5V7.5L12 3Z" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12 12L20 7.5" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                    <path d="M12 12V21" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                    <path d="M12 12L4 7.5" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                    <path d="M16 5.25L8 9.75" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                </svg>`
                        },
                        {
                            link: {name: 'boms_list', query: {tab: 'product'}},
                            title: 'BOMs',
                            icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    class="flex-shrink-0 h-5 w-5">
                                    <path
                                    d="M19.428 15.4282C19.1488 15.149 18.7932 14.9587 18.406 14.8812L16.0185 14.4037C14.7101 14.1421 13.3519 14.324 12.1585 14.9207L11.8411 15.0793C10.6477 15.676 9.28948 15.8579 7.98113 15.5963L6.04938 15.2099C5.39366 15.0788 4.71578 15.284 4.24294 15.7569M7.9998 4H15.9998L14.9998 5V10.1716C14.9998 10.702 15.2105 11.2107 15.5856 11.5858L20.5856 16.5858C21.8455 17.8457 20.9532 20 19.1714 20H4.82823C3.04642 20 2.15409 17.8457 3.41401 16.5858L8.41402 11.5858C8.78909 11.2107 8.9998 10.702 8.9998 10.1716V5L7.9998 4Z"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>`
                        },
                        {
                            link: {name: 'suppliers_list', query: {tab: 'mfg_suppliers'}},
                            title: 'Suppliers',
                            icon:  `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                        class="flex-shrink-0 h-5 w-5">
                                        <path
                                        d="M19 21V5C19 3.89543 18.1046 3 17 3H7C5.89543 3 5 3.89543 5 5V21M19 21L21 21M19 21H14M5 21L3 21M5 21H10M9 6.99998H10M9 11H10M14 6.99998H15M14 11H15M10 21V16C10 15.4477 10.4477 15 11 15H13C13.5523 15 14 15.4477 14 16V21M10 21H14"
                                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>`
                        },
                        {
                            link: {name: 'locations_list', query: {tab: 'cp_wh'}},
                            title: 'Locations',
                            icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                        class="flex-shrink-0 h-5 w-5">
                                        <path
                                        d="M17.6569 16.6569C16.7202 17.5935 14.7616 19.5521 13.4138 20.8999C12.6327 21.681 11.3677 21.6814 10.5866 20.9003C9.26234 19.576 7.34159 17.6553 6.34315 16.6569C3.21895 13.5327 3.21895 8.46734 6.34315 5.34315C9.46734 2.21895 14.5327 2.21895 17.6569 5.34315C20.781 8.46734 20.781 13.5327 17.6569 16.6569Z"
                                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                        d="M15 11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11Z"
                                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>`
                        }
                    ]
                }
            ],
            closedMenu: false
        }
    },
    mounted() {
        if(window.innerWidth < 1181) {
            this.closedMenu = true
        }
    },
    methods: {
        toggleMenu() {
            this.closedMenu = !this.closedMenu
        }
    },
    computed: {
        dashboardLink() {
                return { name: 'dashboard' }
        },
    }
}
</script>