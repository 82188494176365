<template>
    <div class="bg-white opacity-95 fixed w-full h-screen left-0 top-0 z-50 overflow-y-auto">
        <div class="px-6 py-2 w-full flex justify-between align-top">
            <input id="global_search_field" v-model="searchText" @keyup.enter="globalSearch" 
                :disabled="isLoading"
                class="border-0 w-full focus:outline-none text-3xl text-black" 
                placeholder="Search purchase orders, production plans and others" />
            <button @click="closeSearchModal(0)" class="cursor-pointer mt-4 mr-5 bg-transparent p-3 rounded-full hover:bg-gray-50" aria-label="close modal" role="button">
                <svg xmlns="http://www.w3.org/2000/svg" class="text-black icon icon-tabler icon-tabler-x" width="40" height="40"
                    viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round"
                    stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
            </button>
        </div>
        <div class="px-7 text-black font-normal">
            <span v-if="searchText.length && Object.keys(searchResponse).length == 0">Prese enter to search</span>
            <span v-if="searchTextCopy.length && !isLoading">{{ totalSearchResults ? totalSearchResults : 'No' }} results for "{{ searchTextCopy }}"</span>
        </div>
        <template v-if="isLoading">
            <div class="h-screen w-full flex items-center justify-center">
                <svg class="animate-spin -ml-1 mr-3 h-14 w-14 mt-2 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
        </template>
        <template v-else-if="searchTextCopy.length">
            <div v-if="Object.keys(searchResponse).length" class="py-3 px-7 grid grid-cols-2 gap-4">
                <div v-for="(searchRes, searchModel) in searchResponse">
                    <div class="w-full">
                        <div class="capitalize text-lg font-semibold text-gray-600 flex justify-between items-center">
                            <h3>{{searchModel.replaceAll('_', ' ')}}</h3>
                            <h4>{{searchRes.length}}</h4>
                        </div>
                        <hr>
                        <div v-for="eachItem in computedSearchRes(searchModel)">
                            <div class="py-1 flex items-center">
                                <div class="flex items-center" v-if="'part_brand_ids' in eachItem['_source']">
                                    <img v-for="brandId in brandIds(eachItem['_source']['part_brand_ids'])" 
                                        class="w-6 h-6 pr-1 rounded-full inline" :src="brandDetails[brandId]['logo']" alt="Brand Logo">
                                </div>
                                <div class="w-full hover:text-blue-500 text-gray-500 flex items-center justify-between align-center">
                                    <a href="javascript:void(0)" v-if="searchLink(eachItem)" @click="closeSearchModal(eachItem)" 
                                    class="w-5/6 hover:underline underline-offset-1" v-html="searchTitle(eachItem)"></a>
                                    <div v-else class="w-5/6" v-html="searchTitle(eachItem)"></div>
                                    <div v-if="searchItemStatus(eachItem)" v-html="searchItemStatus(eachItem)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="cursor-pointer px-1 text-base text" @click="showMore(searchModel, true)"
                            v-if="(!(searchModel in searchResponseLimit) || !searchResponseLimit[searchModel]) && searchResponse[searchModel].length > 10">
                            See more {{ searchModel.replaceAll('_', ' ') }} results
                        </div>
                        <div @click="showMore(searchModel, false)" class="cursor-pointer px-1 text-base text" 
                            v-if="searchModel in searchResponseLimit && searchResponseLimit[searchModel] && searchResponse[searchModel].length > 10">
                            View less
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="w-full flex-col h-screen flex items-center justify-center">
                <img src="@/assets/images/no-data.png" class="mx-auto">
                <p class="text-2xl font-bold leading-loose text-gray-600">We couldn’t find it.</p>
                <p class="text-base leading-normal text-gray-600">Try another search term or keyword.</p>
            </div>
        </template>
    </div>    
</template>
<script>
import axios from 'axios';

export default {
    emits: ['closeSearch'],
    data() {
        return {
            searchQuery: '',
            isLoading: false,
            searchResponse: {},
            searchText: '',
            searchTextCopy: '',
            brands: [],
            bolStatus: {},
            bolInboundShipmentStatus: {},
            productionPlanStatus: {},
            searchResponseLimit: {},
            totalSearchResults: 0
        }
    },
    mounted() {
        this.searchQuery = ''
        this.searchTextCopy = ''
    },
    methods: {
        closeSearchModal(item = null) {
            this.searchQuery = ''
            this.searchTextCopy = ''
            this.$emit('closeSearch')
            if (item) {
                window.location.replace(this.searchLink(item).href)
            }
        },
        globalSearch(event) {
            if (this.searchText.length <= 0) {
                return
            }
            this.isLoading = true
            axios({
                method: 'get',
                url: `/global-search/search?search_text=${this.searchText}`,
            }).then(res => {
                if (res.status == 200) {
                    this.isLoading = false;
                    if (res.data) {
                        this.searchResponse= res.data.search_response ?? {}
                        this.brands= res.data.brands ?? {}
                        this.bolStatus= res.data.bol_statues ?? {}
                        this.bolInboundShipmentStatus= res.data.bol_inbound_shipment_status ?? {}
                        this.productionPlanStatus= res.data.production_plan_statues ?? {}
                        this.totalSearchResults = res.data.total_search_results ?? 0
                    }
                    this.clearSearchInput()
                }
            }).catch(err => {
                this.isLoading = false;
                console.log(err)
            })
        },
        clearSearchInput() {
            this.searchTextCopy = this.searchText
            this.searchText = ''
            this.searchResponseLimit = {}
        },
        showMore(searchModal, showMoreResults) {
            this.searchResponseLimit[searchModal] = showMoreResults
        }
    },
    computed: {
        searchTitle() {
            return searchItem => {
                if (searchItem['_index'] == 'kin_es_part_index') {
                    return `<span class="font-semibold">${searchItem['_source']['sku']}</span> ${searchItem['_source']['name']}`
                } else if (searchItem['_index'] == 'kin_es_part_lot_index') {
                    return `<span class="font-semibold">${searchItem['_source']['sku']}</span> ${searchItem['_source']['identifier']}`
                } else if (searchItem['_index'] == 'kin_es_skid_index') {
                    return `${searchItem['_source']['identifier']}`
                } else if (searchItem['_index'] == 'kin_es_part_sub_lot_index') {
                    return `<span class="font-semibold">${searchItem['_source']['sku']}</span> ${searchItem['_source']['identifier']}`
                } else if (searchItem['_index'] == 'kin_es_bol_index') {
                    return `${searchItem['_source']['bol_label']}`
                } else if (searchItem['_index'] == 'kin_es_production_plan_index') {
                    return `${searchItem['_source']['id_label']} <span class="font-semibold">${searchItem['_source']['sku']}</span>`
                } else if (searchItem['_index'] == 'kin_es_purchase_order_index') {
                    return `PO${searchItem['_source']['po_number']}`
                } else if (searchItem['_index'] == 'kin_es_bol_inbound_shipment_index') {
                    return `<span class="font-semibold">SP-${searchItem['_source']['id']} ${searchItem['_source']['sku']}</span> ${searchItem['_source']['description']}`
                } else if (searchItem['_index'] == 'kin_es_bol_outbound_shipment_index') {
                    return `<span class="font-semibold">${searchItem['_source']['bol_label']} ${searchItem['_source']['sku']}</span> ${searchItem['_source']['description']}`
                } else if (searchItem['_index'] == 'kin_es_bol_direct_shipment_index') {
                    return `<span class="font-semibold">${searchItem['_source']['bol_label']} ${searchItem['_source']['sku']}</span> ${searchItem['_source']['description']}`
                } else if (searchItem['_index'] == 'kin_es_bol_simple_purchase_shipment_index') {
                    return `<span class="font-semibold">${searchItem['_source']['bol_label']} ${searchItem['_source']['sku']}</span> ${searchItem['_source']['description']}`
                } else if (searchItem['_index'] == 'kin_es_bol_customer_return_shipment_index') {
                    return `<span class="font-semibold">${searchItem['_source']['bol_label']} ${searchItem['_source']['sku']}</span> ${searchItem['_source']['description']}`
                } else if (searchItem['_index'] == 'kin_es_location_index') {
                    return `${searchItem['_source']['name']}`
                } else if (searchItem['_index'] == 'kin_es_supplier_index') {
                    return `${searchItem['_source']['name']} (${searchItem['_source']['initial']})`
                } else if (searchItem['_index'] == 'kin_es_bol_inter_warehouse_shipment_index') {
                    return `<span class="font-semibold">WH-${searchItem['_source']['bol_id']} ${searchItem['_source']['sku']}</span> ${searchItem['_source']['description']}`
                }
                return ''
            }
        },
        searchItemStatus() {
            return searchItem => {
                if (searchItem['_index'] == 'kin_es_bol_index') {
                    let bolStatusId = searchItem['_source']['bol_status_id']
                    if (bolStatusId in this.bolStatus) {
                        const bolStatus = this.bolStatus[bolStatusId]
                        return `<span class="font-normal text-sm py-1 px-2 bg-white border rounded border-neutral-200 text-center" style="color: ${bolStatus.color}">
                        ${bolStatus.name}
                        </span>`
                    }
                } 
                if (searchItem['_index'] == 'kin_es_production_plan_index') {
                    let productionPlanStatusId = searchItem['_source']['production_plan_status_id']
                    if (productionPlanStatusId in this.productionPlanStatus) {
                        const productionPlanStatus = this.productionPlanStatus[productionPlanStatusId]
                        return `<span class="font-normal text-sm py-1 px-2 bg-white border rounded text-center" style="color: ${productionPlanStatus.color}">
                        ${productionPlanStatus.name}
                        </span>`
                    }
                } 
                if (searchItem['_index'] == 'kin_es_purchase_order_index') {
                    let status_color = 'text-primary-500'
                    if (searchItem['_source']['status_name'] == 'Closed') {
                        status_color = 'text-neutral-800'
                    } else if (searchItem['_source']['status_name'] == 'Deleted') {
                        status_color = 'text-red-500'
                    }
                    return `<span class="py-1 text-sm px-2 bg-white border rounded text-center hover:bg-neutral-50 ${status_color}">
                    ${searchItem['_source']['status_name']}
                    </span>`
                } 
                if (searchItem['_index'] == 'kin_es_bol_inbound_shipment_index') {
                    let inboundShipmentId = searchItem['_source']['bol_inbound_shipment_status_id']
                    if (inboundShipmentId in this.bolInboundShipmentStatus) {
                        const inboundShipmentStatus = this.bolInboundShipmentStatus[inboundShipmentId]
                        return `<span class="font-normal text-sm py-1 px-2 bg-white border rounded text-center" style="color: ${inboundShipmentStatus.color}">
                        ${inboundShipmentStatus.name}
                        </span>`
                    }
                }
                return ''
            }
        },
        searchLink() {
            const bol_types = this.constants.bol.bol_type
            return searchItem => {
                let url = ''
                if (searchItem['_index'] == 'kin_es_part_index') {
                    url = this.$router.resolve({ name: 'parts_detail', params: { id: searchItem['_source']['id'] } })
                } else if (searchItem['_index'] == 'kin_es_part_lot_index') {
                    url = this.$router.resolve({ name: 'lots_detail', params: { id: searchItem['_source']['id'] } })
                } else if (searchItem['_index'] == 'kin_es_skid_index') {
                    url = this.$router.resolve({ name: 'skids_detail', params: { id: searchItem['_source']['id'] } })
                } else if (searchItem['_index'] == 'kin_es_part_sub_lot_index') {
                    url = this.$router.resolve({ name: 'boxes_detail', params: { id: searchItem['_source']['id'] } })
                } else if (searchItem['_index'] == 'kin_es_bol_index') {
                    if (searchItem['_source']['bol_type'] == bol_types.outbound) {
                        url = this.$router.resolve({ name: 'outbound_detail', params: { id: searchItem['_source']['id'] } })
                    } else if (searchItem['_source']['bol_type'] == bol_types.inbound) {
                        url = this.$router.resolve({ name: 'inbound_detail', params: { id: searchItem['_source']['id'] } })
                    } else if (searchItem['_source']['bol_type'] == bol_types.direct) {
                        url = this.$router.resolve({ name: 'direct_bol_detail', params: { id: searchItem['_source']['id'] } })
                    } else if (searchItem['_source']['bol_type'] == bol_types.return) {
                        url = this.$router.resolve({ name: 'inbound_return_detail', params: { id: searchItem['_source']['id'] } })
                    } else if (searchItem['_source']['bol_type'] == bol_types.simple_purchase) {
                        url = this.$router.resolve({ name: 'inbound_simple_purchase_detail', params: { id: searchItem['_source']['id'] } })
                    }
                } else if (searchItem['_index'] == 'kin_es_production_plan_index') {
                    url = this.$router.resolve({ name: 'production_plan_detail', params: { id: searchItem['_source']['id'] } })
                } else if (searchItem['_index'] == 'kin_es_purchase_order_index') {
                    url = this.$router.resolve({ name: 'purchase_order_detail', params: { id: searchItem['_source']['id'] } })
                } else if (searchItem['_index'] == 'kin_es_bol_inbound_shipment_index') {
                    url = this.$router.resolve({ name: 'bol_inbound_shipment_detail', params: { id: searchItem['_source']['id'] } })
                } else if (searchItem['_index'] == 'kin_es_bol_outbound_shipment_index') {
                    url = this.$router.resolve({ name: 'outbound_detail', params: { id: searchItem['_source']['bol_id'] } })
                } else if (searchItem['_index'] == 'kin_es_bol_direct_shipment_index') {
                    url = this.$router.resolve({ name: 'direct_bol_detail', params: { id: searchItem['_source']['bol_id'] } })
                } else if (searchItem['_index'] == 'kin_es_bol_simple_purchase_shipment_index') {
                    url = this.$router.resolve({ name: 'inbound_simple_purchase_detail', params: { id: searchItem['_source']['bol_id'] } })
                } else if (searchItem['_index'] == 'kin_es_bol_customer_return_shipment_index') {
                    url = this.$router.resolve({ name: 'inbound_return_detail', params: { id: searchItem['_source']['bol_id'] } })
                } else if (searchItem['_index'] == 'kin_es_bol_inter_warehouse_shipment_index') {
                    url = this.$router.resolve({ name: 'inter_wh_bol_detail', params: { id: searchItem['_source']['bol_id'] } })
                } else if (searchItem['_index'] == 'kin_es_location_index') {
                    if (searchItem['_source']['supplier_id']) {
                        url = this.$router.resolve({ name: 'suppliers_detail', params: { id: searchItem['_source']['supplier_id'] }, query: { tab: 'inventory' } })
                    } else {
                        url = this.$router.resolve({ name: 'locations_detail', params: { id: searchItem['_source']['id'] } })
                    }
                } else if (searchItem['_index'] == 'kin_es_supplier_index') {
                    url = this.$router.resolve({ name: 'suppliers_detail', params: { id: searchItem['_source']['id'] } })
                }
                return url
            }
        },
        brandDetails() {
            const brandObj = {}
            this.brands.map(brand => brandObj[brand.id] = brand)
            return brandObj
        },
        brandIds() {
            return brandIdString => {
                if (brandIdString) {
                    return brandIdString.split(',')
                }
                return []
            }
        },
        computedSearchRes() {
            return searchModal => {
                if(searchModal in this.searchResponseLimit && this.searchResponseLimit[searchModal]) {
                    return [...this.searchResponse[searchModal]]
                } else {
                    return [...this.searchResponse[searchModal].slice(0, 10)]
                }
            }
        }
    }
}
</script>
<style scoped>

</style>