export default {
  bol_type: {
    outbound: 0,
    inbound: 1,
    direct: 2,
    return: 3,
    simple_purchase: 4,
    inter_warehouse: 5,
  },
  bol_type_label: {
    0: 'outbound',
    1: 'inbound',
    2: 'direct',
    3: 'return',
    4: 'simple_purchase',
    5: 'Inter warehouse',
  },
  bol_colors: {
    0: 'bg-pink-500',
    1: 'bg-green-500',
    2: 'bg-yellow-500',
    3: 'bg-yellow-500',
    4: 'bg-green-500',
    5: 'bg-blue-500'
  },
  bol_priority: {
    high: 0,
    medium: 10,
    low: 20,
  },
  bol_status: {
    draft: 1,
    pick: 2,
    freight: 3,
    ready: 4,
    shipped_out: 5,
    cancelled: 6,
  },
  bol_inbound_status: {
    draft: 7,
    pre_receive: 8,
    freight: 9,
    count: 10,
    verify: 11,
    shipped_in: 12,
    cancelled: 13,
  },
  bol_direct_status: {
    draft: 14,
    receive: 15,
    shipped: 16,
    cancelled: 17,
  },
  bol_simple_purchase: {
    draft: 22,
    in_progress: 23,
    released: 24,
    cancelled: 25,
    complete: 32,
  },
  customer_return_statuses: {
    draft: 18,
    in_progress: 19,
    released: 20,
    cancelled: 21
  },
  inter_warehouse_status: {
    draft: 26,
    pick: 27,
    pack: 28,
    count: 29,
    complete: 30,
    cancelled: 31,
  },
  procurement_type_id: {
    supplied: 1,
    invoiced: 2,
    token: 0,
  },
  freight_details: {
    uoms: ["skids", "boxes"],
  },
  bolIcons: {
        0: `<svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
            <rect x="0.25" width="32" height="32" rx="16" fill="#EC4899" fill-opacity="0.2"/>
            <path d="M9.58301 19.3334L9.58301 20.1667C9.58301 21.5475 10.7023 22.6667 12.083 22.6667L20.4163 22.6667C21.7971 22.6667 22.9163 21.5475 22.9163 20.1667L22.9163 19.3334M19.583 12.6667L16.2497 9.33341M16.2497 9.33341L12.9163 12.6667M16.2497 9.33341L16.2497 19.3334" stroke="#EC4899" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
        1: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <rect width="32" height="32" rx="16" fill="#10B981" fill-opacity="0.2"/>
            <path d="M9.33301 19.3334L9.33301 20.1667C9.33301 21.5475 10.4523 22.6667 11.833 22.6667L20.1663 22.6667C21.5471 22.6667 22.6663 21.5475 22.6663 20.1667L22.6663 19.3334M19.333 16.0001L15.9997 19.3334M15.9997 19.3334L12.6663 16.0001M15.9997 19.3334L15.9997 9.33341" stroke="#10B981" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
        2: `<svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
            <rect x="0.5" width="32" height="32" rx="16" fill="#F59E0B" fill-opacity="0.2"/>
            <path d="M20.6667 12.6667L24 16.0001M24 16.0001L20.6667 19.3334M24 16.0001L9 16.0001" stroke="#F59E0B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
        3: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <rect width="32" height="32" rx="16" fill="#10B981" fill-opacity="0.2"/>
            <path d="M9.33301 19.3334L9.33301 20.1667C9.33301 21.5475 10.4523 22.6667 11.833 22.6667L20.1663 22.6667C21.5471 22.6667 22.6663 21.5475 22.6663 20.1667L22.6663 19.3334M19.333 16.0001L15.9997 19.3334M15.9997 19.3334L12.6663 16.0001M15.9997 19.3334L15.9997 9.33341" stroke="#10B981" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
        4: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <rect width="32" height="32" rx="16" fill="#10B981" fill-opacity="0.2"/>
            <path d="M9.33301 19.3334L9.33301 20.1667C9.33301 21.5475 10.4523 22.6667 11.833 22.6667L20.1663 22.6667C21.5471 22.6667 22.6663 21.5475 22.6663 20.1667L22.6663 19.3334M19.333 16.0001L15.9997 19.3334M15.9997 19.3334L12.6663 16.0001M15.9997 19.3334L15.9997 9.33341" stroke="#10B981" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
        5: `<svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
            <rect x="0.75" width="32" height="32" rx="16" fill="#0EA5E9" fill-opacity="0.2"/>
            <g clip-path="url(#clip0_1335_361470)">
            <path d="M15.917 19.3333H24.2503" stroke="#0EA5E9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.917 19.3333L19.2503 22.6666" stroke="#0EA5E9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.917 19.3333L19.2503 16" stroke="#0EA5E9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.5833 12.6667H9.25" stroke="#0EA5E9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.5833 12.6667L14.25 16.0001" stroke="#0EA5E9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.5833 12.6666L14.25 9.33325" stroke="#0EA5E9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_1335_361470">
            <rect width="20" height="20" fill="white" transform="translate(6.75 6)"/>
            </clipPath>
            </defs>
        </svg>`
    }
};
